/*
 * Api helper File
 * Use for all Api.
 **/
/*
npm run build
*/
import axios from 'axios'

import cookie from 'react-cookies'

// const MainUrl_V3 = 'https://uatapi.expertcover.in/api/v3/'
// const APP_URL_V3 = 'https://uatapi.expertcover.in/'

const MainUrl_V3 = "https://api.expertcover.in/api/v3/";
const APP_URL_V3 = "https://api.expertcover.in/";

// const MainUrl_V3 = "http://localhost:3341/api/v3/";
// const APP_URL_V3 = "http://localhost:3341/";

// const MainUrl_V3 = 'https://api.expertcover.in/api/v3/'
// const APP_URL_V3 = 'https://api.expertcover.in/'

export function APPURL () {
  return APP_URL_V3
}

//Post Data Functions.
export function PostData_V3 (url, data) {
  // body..
  //
  var headers = {
    'Content-Type': 'application/json',
    'X-localization': 'en'
  }
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      return errorStatus
    })
}

//Get Data Functions.
export function GetData_V3 (url, data) {
  // body...

  var headers = {
    'Content-Type': 'application/json'
    //'Authorization':'Bearer '+Token,
    //'X-localization':'en'
  }
  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl_V3 + url, data, { headers: headers })
    .then(res => {
      //console.log(res);
      //console.log(res.data);
      return res.data
    })
    .catch(error => {
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      //console.log(errorStatus.data);
      return errorStatus
    })
}

//Post Data with token
//Post Data Functions.
export function PostDataWithToken_V3 (url, data) {
  // body..
  //
  let tokens = ''
  if (cookie.load('access_token')) {
    tokens = cookie.load('access_token')
  }
  var headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + tokens
  }
  return axios
    .post(MainUrl_V3 + url, data, { headers: headers })
    .then(response => {
      //console.log(res);
      //console.log(res.data);
      return response.data
    })
    .catch(error => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      //console.log(errorStatus.data);
      return errorStatus
    })
}

//Post Data Functions.
export function PostImageDataWithToken (url, data) {
  // body..
  //
  let tokens = ''
  if (cookie.load('access_token')) {
    tokens = cookie.load('access_token')
  }
  var headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + tokens
  }
  return axios
    .post(MainUrl_V3 + url, data, { headers: headers })
    .then(response => {
      //console.log(res);
      //console.log(res.data);
      return response.data
    })
    .catch(error => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      //console.log(errorStatus.data);
      return errorStatus
    })
}
//Get Data with token Functions.
export function GetDataWithToken (url, data) {
  let tokens = ''

  if (cookie.load('access_token')) {
    tokens = cookie.load('access_token')
  }
  let config = {
    headers: {
      Authorization: 'Bearer ' + tokens
    },
    params: {}
  }

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl_V3 + url, config)
    .then(res => {
      //console.log(res);
      return res.data
    })
    .catch(error => {
      console.log('errors', error)
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      //console.log(errorStatus.data);
      return errorStatus
    })
}

export function PostVideoDataWithToken (url, data, onUploadProgress) {
  // body..
  //
  let tokens = ''
  if (cookie.load('access_token')) {
    tokens = cookie.load('access_token')
  }
  var headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + tokens
  }
  return axios
    .post(MainUrl_V3 + url, data, {
      headers: headers,
      onUploadProgress: onUploadProgress
    })
    .then(response => {
      //console.log(res);
      //console.log(res.data);
      return response.data
    })
    .catch(error => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      //console.log(errorStatus.data);
      return errorStatus
    })
}
